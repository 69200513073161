import LayoutNew from "../components/shared/LayoutNew";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { Form, Button, InputGroup, ButtonGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AuthContext from "../context/AuthContext";
import ConfirmPin from "../components/ConfirmPin";
const WithDraw = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    clearErrors,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const { userCoins, amounutRefresh, user } = useContext(AuthContext);
  const [bank, setBank] = useState([]);
  const [confirmPin, setConfirmPin] = useState(false);
  const [pin, setPin] = useState("");
  const [afterData, setAfterData] = useState({});
  const onSubmit = async (body) => {
    setConfirmPin(true);
    setAfterData(body);
  };
  const [isLoader, setLoader] = useState(false);
  const AfterPinSubmit = async () => {
    setLoader(true);
    let body = {
      amount: afterData?.amount,
      bank: afterData?.bank,
      AccountName: afterData?.accountName,
      BankAccount: afterData?.bankAccount,
      phone_number: user?.user?.username,
      pin: pin,
    };
    const { status, data } = await apiPost(apiPath.withdrawal, body);
    if (status == 200) {
      if (data?.success) {
        toast.success(data?.message);
        amounutRefresh();
        reset();
        setAfterData({});
        setPin("");
        setConfirmPin(false);
        setLoader(false);
      } else {
        toast.error(data?.message);
        reset();
        setAfterData({});
        setPin("");
        setConfirmPin(false);
      }
    } else {
      toast.error(data?.message);
      reset();
      setAfterData({});
      setPin("");
      setConfirmPin(false);
      setLoader(false);
    }
  };

  const getBank = async () => {
    const { status, data } = await apiPost(apiPath.getBank, {
      type: "withdrawal",
    });
    if (status == 200) {
      if (data?.success) {
        setBank(data?.results?.bankList);
      }
    }
  };
  const getBankDetail = async (body) => {
    const { status, data } = await apiPost(apiPath.getBankDetail, {
      bank: body,
    });
    if (status == 200) {
      if (data?.success) {
        // setValue("accountName", data?.results?.bankDetail?.account_name);
        setValue("accountName", "Personal");
        // setValue("bankAccount", data?.results?.bankDetail?.account_number);
      }
    }
  };

  useEffect(() => {
    getBank();
  }, []);

  const { t } = useTranslation();
  return (
    <LayoutNew>
      <div>
        <div class="p-title title-box">
          <IoMdArrowBack onClick={() => navigate(-1)} size={30} />
          <div class="title w-100">{t("Withdraw_Transaction")}</div>
        </div>
        <div className="main dw-p withdraw-box page-content-box w-100 bg-gradual-black">
          <div className="walletInfo-wrapper w-100 common-box dw-box">
            <div className="tol-wal-bal-box gap">
              <div className="item">
                <span>{t("Total_Wallet_Balance")}</span>
                <br />
                <span class="amt">BDT {userCoins?.balance || 0}</span>
              </div>
              <div className="item walletBalance-outer border-t mt-2 pt-1">
                <span>
                  {" "}
                  {t("Main_Wallet")}: BDT{" "}
                  <span className="walletBalance">
                    {userCoins?.balance || 0}
                  </span>
                </span>
                <span
                  onClick={() => navigate("/withdraw-history")}
                  className="walletBalance-button"
                >
                  WithDraw transction history
                </span>
              </div>
            </div>
            {/* <div className="timer-wrapper w-100 m-auto flex">
              <div className="timer-title">
                <i className="fas fa-clock"></i>
                <span>Refresh in</span>
              </div>
              <div className="timer-val">
                <span className="balTimerVal text-yellow">00</span>{" "}
                <span className="text-yellow">seconds</span>.
              </div>
              <div className="button-box font-10">
                <button className="bg-gradient-secondary btn-mainRestoreAll btn-check">
                  RESTORE ALL
                </button>
              </div>
            </div> */}
          </div>
          <div className="usrTrans-wrapper common-box form-f mb-66">
            <div className="withdraw-form usrTrans-form">
              <Form
                onSubmit={handleSubmit(onSubmit)}
                id="deposit_form"
                class="deposit_form"
              >
                <div class="usrTrans-seperate bankInfoField">
                  <div class="transaction-title">
                    <span>{t("Bank")}</span>
                    <span class="important-icon">*</span>
                  </div>
                  <div class="transaction-option m-auto">
                    <select
                      {...register("bank", {
                        required: {
                          value: true,
                          message: t("Please_select_bank"),
                        },
                        onChange: (e) => {
                          getBankDetail(e?.target?.value);
                        },
                      })}
                      id="depositBankId"
                      class="gatewayBankSelect"
                    >
                      <option value="">{t("Select_Bank")}</option>
                      {bank?.length > 0 &&
                        bank?.map((res) => {
                          return (
                            <option value={res?._id}>{res?.bank_name}</option>
                          );
                        })}
                    </select>
                  </div>
                  {errors?.bank?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.bank?.message}
                    </div>
                  )}
                </div>
                {!isEmpty(watch("bank")) && (
                  <>
                    <div class="usrTrans-seperate bankInfoField bankInfo">
                      <div class="transaction-title">
                        <span>Bank account / number</span>
                        <span class="copyBtn bg-gradient-secondary">
                          <i class="fas fa-copy"></i>
                        </span>
                      </div>
                      <div class="transaction-option m-auto">
                        <input
                          {...register("bankAccount", {
                            required: {
                              value: true,
                              message: "Please enter bank account number",
                            },
                          })}
                          class="text-input"
                          id="depositAccNo"
                        />
                      </div>{" "}
                      {errors?.bankAccount?.message && (
                        <div class="transaction-errMsg text-danger depositMsg">
                          {errors?.bankAccount?.message}
                        </div>
                      )}
                    </div>
                    <div class="usrTrans-seperate bankInfoField bankInfo">
                      <div class="transaction-title">
                        <span>{t("Account_Name")}</span>
                        <span class="copyBtn bg-gradient-secondary">
                          <i class="fas fa-copy"></i>
                        </span>
                      </div>
                      <div class="transaction-option m-auto">
                        <input
                        style={{color: `#fff`}}
                          {...register("accountName", {
                            required: {
                              value: true,
                              message: "Please enter account name",
                            },
                          })}
                          disabled
                          class="text-input"
                          id="depositAccName"
                        />
                      </div>{" "}
                      {errors?.accountName?.message && (
                        <div class="transaction-errMsg text-danger depositMsg">
                          {errors?.accountName?.message}
                        </div>
                      )}
                    </div>
                  </>
                )}

                <div class="usrTrans-seperate deposit-amount">
                  <div class="transaction-title">
                    <span>{t("Amount")}</span>
                    <span class="important-icon">*</span>
                  </div>
                  <div class="transaction-option m-auto">
                    <input
                      {...register("amount", {
                        required: {
                          value: true,
                          message: t("Please_enter_amount"),
                        },
                        validate: (value) => {
                          if (value > 199) {
                            if (value > 99999999) {
                              return t("Max_8_digit");
                            }
                          } else {
                            return t("Amount_200");
                          }
                        },
                      })}
                      type="number"
                      class="text-input"
                      id="depositAmt"
                      placeholder="0.00"
                    />
                  </div>
                  {errors?.amount?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.amount?.message}
                    </div>
                  )}
                </div>

                <div className="usrTrans-seperate">
                  <div className="transaction-title">
                    <span>{t("Mobile_Number")}</span>
                    <span className="important-icon"></span>
                  </div>
                  <div className="transaction-option m-auto">
                    <input
                    style={{color: `#fff`}}
                      className="text-input disabled"
                      id="userMobileNum"
                      value={user?.user?.username}
                      disabled
                    />
                  </div>
                </div>

                <div className="usrTrans-seperate">
                  <div className="transaction-option">
                    <div className="transaction-btn">
                      <input
                        type="hidden"
                        id="withdrawBankCode"
                        name="withdrawBankCode"
                        value="BKASH"
                      />
                      <button
                        type="submit"
                        className="btn-submit bg-gradient-primary"
                      >
                        {t("WITHDRAW")}
                      </button>
                    </div>
                  </div>
                </div>

                <input id="maxWithdrawCountPerDay" value="0" type="hidden" />
              </Form>
            </div>
          </div>
        </div>
      </div>
      {confirmPin && !isEmpty(afterData) && (
        <ConfirmPin
        isLoader={isLoader}
          show={confirmPin}
          handelClose={() => setConfirmPin(false)}
          pin={pin}
          setPin={setPin}
          onSubmit={AfterPinSubmit}
        />
      )}
    </LayoutNew>
  );
};

export default WithDraw;
